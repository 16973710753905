//Credit for breakpoint mixins and screen size: https://medium.com/codeartisan/breakpoints-and-media-queries-in-scss-46e8f551e2f2
$screen-xsm-min: 400px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin xsm {
   @media (min-width: #{$screen-xsm-min}) {
       @content;
   }
}
@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content;
   }
}
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}

html {
	font-size: 8px;

	@include sm {
		font-size: 10px;
	}

	@include md {
		font-size: 14px;
	}
}

body {
	width: 100%;
	height: 100%;
	padding:0;
	margin:0;
	background-color: #E2E8F0;
	background-image: url("/bg-noise.png");
	background-blend-mode: multiply;
	display: flex;
	justify-content: space-around;
	font-family: 'Inter', sans-serif;
}

input[type="text"] {
	background-color: #F1F5F9;
	border-radius: 8px;
	border: 1px solid black;
	box-shadow: none;
	height: 2rem;
	padding:0.25rem 0.5rem;

	&.huge {
		height: 5rem;
		font-size: 2rem;
		width: 100%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	&.small {
		border-radius: 4px;
	}

}

.alley {
	padding-top: 5rem;
	width:90vw;
	@include xsm {
		width:50rem;
	}
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1rem;
}

.card {
	border-radius: 8px;
	background-color: white;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	gap:1rem;

	&__header {
		display: flex;
		align-items: center;
		gap:1rem;

		&__title {
			text-align: left;
			font-size: 1.5rem;
			font-style: normal;
			font-weight: 700;

			&--loading {
				background-color: #020617;
				border-radius: 8px;
				height: 1.5rem;
				width: 20ch;
			}
		}
	}

    &--motd {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #f0f9ff;
        border: 2px solid #075985;
        color: #075985;
        font-weight: 600;
    }

	&--decision {
		border: 2px solid black;

		&--accept {
			border: 2px solid #052E16;
			background-color: #F7FEE7;
			&__symbol {
				background-color: #052E16;
				padding: 1rem;
				color:white;
				clip-path: circle();
				width:min-content;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		
		&--reject {
			border: 2px solid #451A03;
			background-color: #FFF7ED;
			&__symbol {
				background-color: #451A03;
				padding: 1rem;
				clip-path: circle();
				color:white;
				width:min-content;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		
		&--loading {
			@keyframes phase {
				0% {
					opacity: 1;
				}

				50% {
					opacity: 0.75;
				}

				100% {
					opacity: 1
				}
			}
			animation: phase 0.5s infinite;

			border: 2px solid black;
			background-color: #F8FAFC;

			&__symbol {
				background-color: #020617;
				padding: 1rem;
				clip-path: circle();
				width:min-content;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&__workshops {
			background-color: white;
			border: 1px solid #052E16;
			border-radius: 8px;
			padding: 1rem;
			display: flex;

			flex-direction: column;
			@include md {
				flex-direction: row;
				font-size: inherit;
				flex-wrap:wrap;
			}
			gap: 0.5rem;

			&__title {
				color: #000;
				font-weight: 500;
			}

			&__workshop {
				font-size: 1.5rem;
				background-color: #CBD5E1;
				display: flex;
				align-items: center;
				padding: 0.25rem 1rem;
				justify-content: space-between;
				border-radius: 8px;
				@include md {
					font-size:1rem;
				}

				&__name {
				}

				&__delete {
					transform:scale(0.8);
					cursor:pointer;
				}
			}

			&__workshop--add {
				padding: 0.2rem;
				cursor: pointer;
			}
		}

	}
}

.tap_form {
	display: flex;
	gap: 1rem;

	&__container {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	&__links {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
	}

	&__title {
		font-weight: 500;
		font-size: 1.25rem;
	}
}

a {
	font-weight: 600;
	color: black;
	text-decoration: none;
}

.ctrl_workshops {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__workshop {
		background-color: #CBD5E1;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		padding: 0.5rem;
		align-items: center;
		font-weight: 500;

		&__delete {
			background-color: #F1F5F9;
			border-radius: 6px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.25rem;
			cursor: pointer;
		}
	}
}

.ctrl_workshop_form {
	display: flex;
}
